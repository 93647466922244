import React from 'react'
import styled from 'styled-components'

const LogoContainer = styled.div`
 

width:100%;
`
const GVDLogo = ({ fill }) => (
  <LogoContainer>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 420.52 109.08">
      <title>GVD Logo</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="ICON" data-name="ICON">
          <path fill={fill ? fill : ""}
            className="cls-1"
            d="M263.07,55.72,284.94,35.4l-22.06-23L232.11,44.51h10.82V55.72Zm51-13.34h7.09V33.84h-7.09v8.54Zm8,0h7.09V33.84H322v8.54ZM239,55.72v-7.6h-16.3l16.56-17.3v-17h10.53v6.05l13.1-13.68,25.21,26.33L321.6,1.33l33.51,31.14L380.32,6.14l13.1,13.68V13.77H404V30.82l16.56,17.3h-16.3v7.6l-36.38,0q-9.93-9.53-20.11-19.12Q334.61,24.24,321.6,12.3L274.3,55.7Zm161.29,0V44.51H411.1L380.32,12.37l-22.05,23,21.87,20.32ZM381.07,42.38V36.84h-4.59v5.54Zm5.16,0V36.84h-4.59v5.54Zm0-11.76h-4.59v5.54h4.59V30.62Zm-5.16,0h-4.59v5.54h4.59V30.62ZM262.14,42.38V36.84h4.59v5.54Zm-5.17,0V36.84h4.6v5.54Zm0-11.76h4.6v5.54H257V30.62Zm5.17,0h4.59v5.54h-4.59V30.62ZM322,52h7.09V43.43H322V52Zm-8,0h7.09V43.43h-7.09Z"
          ></path>
          <polygon
          fill={fill ? fill : ""}
            className="cls-2"
            points="288.32 29.38 302.45 16.25 265.04 5.07 288.32 29.38"
          ></polygon>
          <polygon
          fill={fill ? fill : ""}
            className="cls-2"
            points="354.89 29.38 340.76 16.25 378.16 5.07 354.89 29.38"
          ></polygon>
        </g>
        <g id="logo">
          <g id="GVD">
            <path fill={fill ? fill : ""} d="M33.72,59.1q-14.25,0-24-8.21T0,29.51Q0,16.25,9.74,8.13T33.72,0Q51,0,59.94,12.74L48.73,18.22a17.47,17.47,0,0,0-6.22-5.35,18.52,18.52,0,0,0-8.79-2.09q-8.64,0-14.25,5.3a17.72,17.72,0,0,0-5.6,13.43,17.72,17.72,0,0,0,5.6,13.43,19.93,19.93,0,0,0,14.25,5.3A23.52,23.52,0,0,0,41.42,47a18.06,18.06,0,0,0,5.79-3.08V37H30.49V26.43H60.7V48.32Q50,59.11,33.72,59.1Z"></path>
            <path fill={fill ? fill : ""} d="M102,58H85.11L60.6.94H76L93.56,44.82,111,.94h15.39Z"></path>
            <path fill={fill ? fill : ""} d="M154.45,58h-25V.94h25q14.91,0,24.27,7.87t9.36,20.7q0,12.83-9.31,20.65T154.45,58Zm0-10.69q9.12,0,14.49-5.13a16.84,16.84,0,0,0,5.36-12.66,17,17,0,0,0-5.22-12.87q-5.22-5-14.63-5H143V47.3Z"></path>
          </g>
          <g id="RENOVATIONS" data-name="RENOVATIONS">
            <path fill={fill ? fill : ""}
              className="cls-3"
              d="M28.19,103.11h-7.1L15.39,92.8H10.86v10.31H4.67v-29H18.26a9.67,9.67,0,0,1,7.12,2.62A9.07,9.07,0,0,1,28,83.44a8.76,8.76,0,0,1-1.85,5.79,8.17,8.17,0,0,1-4.51,2.83ZM17.35,87.36a4.59,4.59,0,0,0,3.13-1.07,3.94,3.94,0,0,0,0-5.7,4.58,4.58,0,0,0-3.13-1.06H10.86v7.83Z"
            ></path>
            <path fill={fill ? fill : ""}
              className="cls-3"
              d="M54,103.11H33.42v-29H54v5.45H39.61v6.13H53.68V91.1H39.61v6.57H54Z"
            ></path>
            <path fill={fill ? fill : ""}
              className="cls-3"
              d="M85.57,103.11h-6L65.75,84.18v18.93H59.56v-29h6.36L79.38,92.32V74.08h6.19Z"
            ></path>
            <path fill={fill ? fill : ""}
              className="cls-3"
              d="M105.82,103.64A14.79,14.79,0,0,1,95,99.39a14.48,14.48,0,0,1-4.3-10.77A14.48,14.48,0,0,1,95,77.85a16.13,16.13,0,0,1,21.78,0A14.48,14.48,0,0,1,121,88.62a14.52,14.52,0,0,1-4.29,10.8A14.94,14.94,0,0,1,105.82,103.64Zm-6.38-8.19a8.92,8.92,0,0,0,12.79,0,10.78,10.78,0,0,0,0-13.66,8.92,8.92,0,0,0-12.79,0,10.85,10.85,0,0,0,0,13.66Z"
            ></path>
            <path fill={fill ? fill : ""}
              className="cls-3"
              d="M141.16,103.11H133.4l-11.24-29h7.06l8.06,22.33,8-22.33h7.06Z"
            ></path>
            <path fill={fill ? fill : ""}
              className="cls-3"
              d="M180.84,103.11h-7.05L172,98.2H159.54l-1.83,4.91h-7.06l11.24-29h7.76ZM170.3,92.76l-4.53-12.49-4.53,12.49Z"
            ></path>
            <path fill={fill ? fill : ""}
              className="cls-3"
              d="M194.43,103.11H188.2V79.53h-8.49V74.08h23.18v5.45h-8.46Z"
            ></path>
            <path fill={fill ? fill : ""} className="cls-3" d="M213.78,103.11h-6.19v-29h6.19Z"></path>
            <path fill={fill ? fill : ""}
              className="cls-3"
              d="M234,103.64a14.83,14.83,0,0,1-10.87-4.25,14.51,14.51,0,0,1-4.29-10.77,14.51,14.51,0,0,1,4.29-10.77,16.15,16.15,0,0,1,21.79,0,14.48,14.48,0,0,1,4.29,10.79A14.52,14.52,0,0,1,245,99.42,15,15,0,0,1,234,103.64Zm-6.38-8.19a8.92,8.92,0,0,0,12.79,0,10.78,10.78,0,0,0,0-13.66,8.92,8.92,0,0,0-12.79,0,10.85,10.85,0,0,0,0,13.66Z"
            ></path>
            <path fill={fill ? fill : ""}
              className="cls-3"
              d="M280.34,103.11h-6L260.52,84.18v18.93h-6.19v-29h6.36l13.47,18.24V74.08h6.18Z"
            ></path>
            <path fill={fill ? fill : ""}
              className="cls-3"
              d="M297.16,103.64q-7.76,0-12.29-4.62l3.36-4.7a12.26,12.26,0,0,0,9.23,3.92,6.37,6.37,0,0,0,3.73-.94A2.74,2.74,0,0,0,302.52,95a2.29,2.29,0,0,0-1.25-2,8.7,8.7,0,0,0-3.09-1.13,40.66,40.66,0,0,1-4-1,35,35,0,0,1-4-1.48,7.35,7.35,0,0,1-4.33-7,7.92,7.92,0,0,1,3-6.29,12,12,0,0,1,8-2.55,16.08,16.08,0,0,1,11.33,4l-3.45,4.52a12,12,0,0,0-8.45-3.17,5.19,5.19,0,0,0-3.05.78A2.5,2.5,0,0,0,292.1,82a2.08,2.08,0,0,0,1.24,1.77,10,10,0,0,0,3.1,1.06,37.55,37.55,0,0,1,4,1,33.32,33.32,0,0,1,4,1.53,7.45,7.45,0,0,1,4.34,7,8.47,8.47,0,0,1-3,6.75Q302.82,103.64,297.16,103.64Z"
            ></path>
            <path fill={fill ? fill : ""}
              className="cls-3"
              d="M320.2,101a8.91,8.91,0,0,1-1.22,4.51,11.52,11.52,0,0,1-3.27,3.59l-2.39-2a7.23,7.23,0,0,0,2.09-1.76,5.67,5.67,0,0,0,1.26-2.33,2.76,2.76,0,0,1-.82.13,2.84,2.84,0,0,1-2.12-.87,3.14,3.14,0,0,1-.85-2.27,3.38,3.38,0,0,1,1-2.45,3.19,3.19,0,0,1,2.39-1,3.7,3.7,0,0,1,2.77,1.2A4.47,4.47,0,0,1,320.2,101Z"
            ></path>
            <path fill={fill ? fill : ""} className="cls-3" d="M343.68,103.11H337.5v-29h6.18Z"></path>
            <path fill={fill ? fill : ""}
              className="cls-3"
              d="M376.18,103.11h-6L356.36,84.18v18.93h-6.19v-29h6.36L370,92.32V74.08h6.18Z"
            ></path>
            <path fill={fill ? fill : ""}
              className="cls-3"
              d="M396.74,103.64a15.41,15.41,0,0,1-11-4.2,14.25,14.25,0,0,1-4.42-10.82,14.23,14.23,0,0,1,4.42-10.81,15.41,15.41,0,0,1,11-4.2A13.09,13.09,0,0,1,408.9,80.7l-5.32,2.61a8,8,0,0,0-2.81-3,7.24,7.24,0,0,0-4-1.2,8.62,8.62,0,0,0-6.53,2.7,10.36,10.36,0,0,0,0,13.66,8.62,8.62,0,0,0,6.53,2.7,7.37,7.37,0,0,0,4-1.17,7.75,7.75,0,0,0,2.81-3l5.32,2.57A13.25,13.25,0,0,1,396.74,103.64Z"
            ></path>
            <path fill={fill ? fill : ""}
              className="cls-3"
              d="M418.53,102.55a3.48,3.48,0,1,1,1-2.48A3.39,3.39,0,0,1,418.53,102.55Z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </LogoContainer>
)
export default GVDLogo
